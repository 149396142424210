@font-face {
  font-family: "swall-diatype-bold";
  src: url("./fonts/SwallStudiosDiatype-Bold.otf") format("opentype");
}
h2,
h3 {
  font-family: "swall-diatype-bold";
}
strong {
  font-family: "swall-diatype-bold";
  font-weight: normal;
}
.mobile-nav {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 4%;
  padding-bottom: 4%;
  border-bottom: 1px solid #bfbfbf;
  /* margin-bottom: 4%; */
  position: sticky;
  top: 0;
  /* left: 0; */
  background: white;
  z-index: 10;
}
.mobile-scroll-progress-bar {
  position: absolute;
  top: 0;
  bottom: 0;
  /* left: 0;
  right: 0;
  height: 55px; */
  width: 100%;
  z-index: -1;
  background: #d7ceff;
  transform-origin: 0%;
}
.mobile-hamburger-menu {
  position: relative;
  width: 10%;
}

.hamburger-icon {
  cursor: pointer;
  width: 50%;
  /* Hamburger icon styles (you can use CSS for a hamburger icon) */
}

.menu-content {
  position: absolute;
  top: calc(100% + 73%);
  left: -50%;
  width: 100vw;
  height: calc(100vh - 7%);
  max-height: calc(100vh - 7%);
  background-color: #fff;
  font-family: "swall-diatype";
  font-size: 24px;
}

.menu-list {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
  display: flex; /* Use flexbox to center vertically */
  flex-direction: column; /* Align items vertically */
  justify-content: center; /* Center items vertically */
  height: 70%; /* Occupy full height of the menu */
}

.menu-list li {
  padding: 8px 0; /* Adjust spacing */
}

.menu-list li a {
  text-decoration: none;
  color: #333; /* Link color */
  /* Add other styles as needed */
}
.mobile-logo {
  width: 70%;
  display: flex;
  justify-content: center;
}
.mobile-logo.navbar-image {
  margin: 0;
}
.mobile-search-cart {
  width: 10%;
  display: flex;
  justify-content: flex-end;
  gap: 30%;
  align-items: center;
}
.mobile-search-icon {
  margin-top: 1%;
}
.mobile-search {
  position: absolute;
  width: 100%;
  background-color: #d7ceff;
  color: var(--blue);
  box-sizing: border-box;
  top: calc(100%);
  font-family: "swall-diatype";
  z-index: -1;
  overflow: hidden;
}
.mobile-search .search-container {
  width: 100%;
}
.mobile-search .search-input-container {
  border-bottom: solid 1px;
}
.mobile-search .search-input-container > input {
  margin-left: 5%;
  font-size: 24px;
  font-family: "swall-diatype";
}
.mobile-search .search-input-container > button {
  margin-right: 5%;
  font-size: 24px;
}
.mobile-search .search-input-container > button,
.mobile-search .search-input-container > input {
  font-family: "swall-diatype";
  box-sizing: border-box;
  padding-bottom: 2%;
}
.mobile-search .suggestions-container {
  font-size: 14px;
  margin-left: 5%;
  width: 89%;
  font-family: "swall-diatype";
  line-height: 140%;
}
.mobile-cart-icon {
  position: relative;
}
.mobile-cart-number {
  position: absolute;
  clip-path: circle(50%);
  width: 2px;
  height: 2px;
  padding: 5px;
  background: var(--blue);
  color: white;
  text-align: center;
  margin: 0;
  bottom: -10%;
  left: 50%;
  font-size: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mobile-cart-footer {
  font-family: "swall-diatype-bold";
  margin-bottom: 5% !important;
  width: 95%;
  margin: auto;
  font-size: 12px;
}
.mobile-cart-footer .cart-subtotal-container > p {
  margin: 0;
  padding: 0;
  padding-bottom: 1%;
}

/* MOBILE HOMEPAGE */

.mobile-homepage {
  width: 100%;
  margin: auto;
  margin-top: 6%;
}

/* mobile-index-and-content */
.mobile-index-and-content {
  min-height: 100vh;
}

/* LATEST VIDEO */

.mobile-latest-video {
  margin-bottom: 20% !important;
  width: 90%;
  margin: auto;
}

/* Stories */

.mobile-stories {
  margin-bottom: 20%;
  min-height: 100vh;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.mobile-stories-page-content .section-title-home {
  margin-top: 8% !important;
}
.mobile-latest-story-card {
  margin-bottom: 15%;
  cursor: pointer;
}

.mobile-latest-story-card > img {
  aspect-ratio: 2/3;
  object-fit: cover;
}

.mobile-latest-story-card-title {
  font-family: "tobias-regular";
  font-size: 32px;
  margin: 0%;
  margin-top: 1%;
  margin-left: 0;
}

.mobile-most-discussed-preview {
  font-family: "swall-diatype";
  font-size: 14px;
  margin-top: 2%;
}

/* Watches */

.mobile-watches {
  margin-top: 2%;
  margin-bottom: 20%;
  display: flex;
  width: 100%;
  justify-content: center;
}

.mobile-watch-preview-card {
  width: 100%;
  cursor: pointer;
}

.mobile-watches .watch-preview-card-details {
  text-align: center;
  font-size: 14px;
  font-family: "swall-diatype";
}

/* Brynn's Pick */

.mobile-featured-watch {
  margin-bottom: 20%;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.mobile-featured-watch-headline {
  font-family: "tobias-regular";
  font-size: 32px;
  text-align: center;
  margin-top: 4%;
  margin-bottom: 4%;
}
.mobile-featured-watch-image {
  cursor: pointer;
}

.mobile-featured-watch-details {
  display: flex;
  flex-wrap: wrap;
}

.mobile-featured-watch-detail-container {
  width: 50%;
  text-align: center;
  margin-top: 3%;
  margin-bottom: 3%;
  font-size: 14px !important;
  font-family: "swall-diatype";
}

.mobile-featured-watch-detail-container > p,
.mobile-featured-watch-detail-container > h5 {
  margin: 1%;
  font-family: "swall-diatype";
}

.mobile-featured-watch-description {
  font-family: "swall-diatype";
  font-size: 14px;
}

/* DIAL DIMEPIECE */

.mobile-dial-dimepiece {
  margin-bottom: 10%;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.mobile-dial-dimepiece-cards-container {
  margin-top: 4%;
  margin-bottom: 20%;
}
.mobile-dial-dimepiece-card:first-of-type {
  margin-top: 10%;
}

.mobile-dial-dimepiece-card {
  display: flex;
  margin-top: 5%;
  flex-direction: column;
  cursor: pointer;
}

.mobile-dial-dimepiece-card-left,
.mobile-dial-dimepiece-card-right {
  width: 100%;
}

.mobile-dial-dimepiece-card-headers > p {
  border-bottom: 1px black solid;
  padding-bottom: 2%;
  font-size: 22px;
  font-family: "tobias-regular";
}

.dial-dimepiece-card-date {
  font-size: 14px;
  font-family: "tobias-regular";
}

/*  NEWSLETTER */
.mobile-newsletter {
  margin-bottom: 10%;
}
.mobile-newsletter .newsletter-input-container {
  width: 95%;
}
.mobile-newsletter > p {
  text-align: center;
  font-size: 14px;
  font-family: "swall-diatype";
}

.mobile-newsletter .newsletter-input-container > input,
.mobile-newsletter .newsletter-input-container > button {
  font-size: 14px;
  font-family: "swall-diatype";
  font-weight: 400;
}
.mobile-newsletter .newsletter-input-container {
  padding: 0;
}
/* NEWSLETTER PAGE */

.mobile-newsletter-page {
  width: 90%;
  margin: auto;
  /* min-height: 43vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 6%;
  /* height: 100vh; */
}
.mobile-newsletter-page > p {
  text-align: center;
  margin-top: 8%;
}

/* mobileindex and content */
.mobile-index {
  display: flex;
  justify-content: space-between;
  font-family: "swall-diatype";
  margin-bottom: 5%;
  padding-left: 5%;
  padding-right: 5%;
  border-bottom: 1px solid #bfbfbf;
  position: relative;
}
.mobile-index .index-link {
  font-size: 12px !important;
}
.mobile-index .stories-page-index-category-header {
  font-size: 12px;
  margin: 4% 0;
  font-family: "swall-diatype-bold";
}
.mobile-index-filter-dialogue {
  position: absolute;
  top: 0;
  height: 95vh;
  left: 0;
  right: 0;
  background: white;
  z-index: 9;
}
.mobile-index-filter-dialogue .mobile-index {
  border: none;
}
.mobile-index-filter-dialogue-left {
  margin-top: 4%;
}
.mobile-index .index-link {
  margin: 0;
  width: fit-content;
  white-space: nowrap;
}
.mobile-content-container {
  width: 100%;
  margin: auto;
}
.mobile-descriptor {
  width: 90%;
  margin: auto;
}
.mobile-descriptor > p {
  text-align: center;
  font-size: 14px;
  font-family: "swall-diatype";
  margin-bottom: 10%;
}
.mobile-content-mapped {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 15%;
}
.mobile-content-mapped .mobile-watch-preview-card {
  width: 50%;
  margin-bottom: 8%;
}
.mobile-content-mapped .watch-preview-card-details {
  text-align: center;
}
.mobile-content-mapped .mobile-latest-story-card {
  width: 90%;
  margin: auto;
  margin-bottom: 10%;
}
.mobile-content-mapped .mobile-latest-story-card > img {
  aspect-ratio: 2/3;
  object-fit: cover;
}
.mobile-content-mapped .mobile-latest-story-card-title {
  margin-top: 2%;
  margin-bottom: 5%;
  font-size: 32px;
}
.search-results .mobile-latest-story-card {
  width: 60%;
}
/* .mobile-content-mapped .mobile-most-discussed-preview {
  display: none;
} */

/* WATCH PAGE */
.mobile-product-images-container {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  width: 100%;
  margin: auto;
}
.mobile-product-images-container::-webkit-scrollbar {
  display: none;
}
.mobile-product-images-container > img {
  margin-right: 2%;
  width: 90%;
  aspect-ratio: 2/2;
}
.mobile-product-images-container > img:first-of-type {
  margin: 0 2% 4% 5%;
}
.mobile-product-images-container > img:last-of-type {
  margin: 0 0 4% 0;
  padding-right: 5%;
}
.mobile-product-container {
  width: 100%;
  margin: 5% auto;
  overflow-x: hidden;
  min-height: 100vh;
}
.mobile-product-description-container {
  width: 90%;
  margin: 2% auto;
  font-size: 14px;
  font-family: "swall-diatype";
}

.mobile-product-container .watch-description-table {
  margin-bottom: 5%;
  margin-top: 5%;
}

.mobile-product-container
  .watch-description-table
  .watch-description-table-row-key {
  font-size: 8px;
}

/* .mobile-product-container
  .watch-description-table
  .watch-description-table-row-value {
  font-size: 14px;
} */

.mobile-product-container .watch-description-buttons-container {
  margin-bottom: 10%;
}

.mobile-product-container .watch-description-summary {
  margin-bottom: 10%;
  font-size: 14px;
  font-family: "swall-diatype";
}

/* Story page */

.mobile-article {
  width: 90%;
  margin: auto;
  text-align: center;
  font-size: 14px;
  min-height: 100vh;
}
.mobile-article .blue {
  margin-top: 10%;
}
.mobile-article .author-photog-container {
  margin-bottom: 5%;
}
.mobile-article-title {
  font-size: 48px;
  font-family: "tobias-regular";
  text-align: center;
  margin-bottom: 5%;
  margin-top: 5%;
}
.mobile-author-photog-container {
  font-family: "swall-diatype";
  font-size: 14px;
  text-align: center;
  margin-bottom: 5%;
}
.mobile-author-photog-container > p {
  margin: 0;
}
.mobile-article .article-sanity-content > p {
  width: 100%;
  font-size: 14px;
  font-family: "swall-diatype";
}

.mobile-article .article-button-container {
  margin-bottom: 10%;
}
.mobile-article .article-button-container > button {
  margin-bottom: 10%;
}
.mobile-article .article-cover-img {
  width: 100%;
  margin-bottom: 5%;
  aspect-ratio: 2/3;
  object-fit: cover;
}
.mobile-article .article-sanity-content .article-images-container > img {
  width: 100% !important;
}
.sanity-mobile-article-image-container > p {
  font-size: 12px;
}
.sanity-mobile-article-image-container {
  margin-top: 2.5%;
  margin-bottom: 2.5%;
}
/* ABOUT */
.mobile-about-page {
  width: 90%;
  margin: auto;
  font-size: 36px;
  font-family: "tobias-regular";
  min-height: 100vh;
}
.mobile-about-page .article-images-container > img {
  width: 100% !important;
}

.mobile-about-page .about-brynn-portrait {
  flex-direction: column;
  margin-top: 5%;
}
.mobile-about-page .about-brynn-portrait-title {
  margin: auto;
  font-family: "swall-diatype-bold";
  text-align: center;
  font-size: 24px;
  margin-bottom: 5%;
  /* font-weight: bold; */
}
.mobile-about-page .about-header-title-full {
  margin-bottom: 0%;
}
.mobile-about-page .about-header-title {
  margin-top: 5%;
  margin-bottom: 5%;
}
.about-brynn-portrait-title-tobias {
  font-family: "tobias-regular";
  font-size: 36px;
  text-align: center;
}
.mobile-about-dimepiece-press {
  margin-bottom: 10%;
}
.mobile-about-dimepiece-press > img {
  cursor: pointer;
}
.mobile-about-dimepiece-press .blue {
  font-size: 36px;
  font-family: "tobias-regular";
  margin-top: 2%;
  margin-bottom: 0;
}
.mobile-about-dimepiece-press-title {
  font-size: 36px;
  font-family: "tobias-regular";
  margin-top: 2%;
  margin-bottom: 0;
  cursor: pointer;
}
.mobile-about-dimepiece-description {
  font-size: 14px;
  font-family: "swall-diatype";
  margin-top: 2%;
  line-height: 130%;
}
.mobile-about-page .newsletter {
  width: 100%;
  margin-bottom: 20%;
}
.mobile-about-page .about-say-hello {
  font-size: 14px;
  margin-bottom: 10%;
}
.mobile-about-page .newsletter-input-container {
  margin-bottom: 10%;
  margin-top: 0;
}
.mobile-about-page .newsletter-input-container > button {
  margin-right: 2%;
}
/* mobile footer */
.mobile-footer {
  background-color: #d7ceff;
  font-size: var(--base-text-size);
  padding: 8% 5%;
  font-size: 14px;
  font-family: "swall-diatype";
}
.mobile-footer-top-container {
  display: flex;
  gap: 2rem;
  font-family: "swall-diatype";
}
.mobile-bold {
  margin-bottom: 10px !important;
  font-size: 14px;
  white-space: nowrap;
  font-family: "swall-diatype";
}
.mobile-footer-column {
  margin-bottom: 8%;
  font-family: "swall-diatype";
}
.mobile-footer-column > p {
  margin: 0;
}
.mobile-footer-column .navbar-link {
  font-size: 14px;
}
.mobile-footer-bottom-container {
  width: 50%;
}
.mobile-footer-newsletter-container {
  margin-bottom: 8%;
}
.mobile-footer-newsletter-container .mobile-bold {
  margin-top: 0;
}
.mobile-footer-newsletter-input-container {
  border-bottom: 1px solid black;
  display: flex;
  justify-content: space-between;
}
.mobile-footer-newsletter-input-container > input {
  outline: none;
  border: none;
  background: #d7ceff;
  padding: 0;
  font-size: 14px;
  font-family: "swall-diatype";
}
.mobile-footer-newsletter-input-container > input::placeholder {
  color: black;
}
.mobile-footer-newsletter-input-container > button {
  font-size: 14px;
  font-family: "swall-diatype";
  font-weight: 400;
}
/* cart */

.mobile-cart-pop-up {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 1vw;
  box-sizing: border-box;
  border: 1px solid black;
  background: white;
  z-index: 12;
  transform-origin: 100%;
  font-family: "swall-diatype";
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.mobile-cart-pop-up .cart-product-cards-container > div:first-of-type {
  padding-top: 0;
}
.mobile-cart-pop-up .cart-price-container {
  margin-right: 2%;
  font-size: 12px !important;
}
.mobile-cart-pop-up .cart-price-container-button > button {
  font-size: 12px !important;
}
.mobile-cart-pop-up .cart-product-cards-hold {
  width: 95%;
  margin: auto;
  font-size: 12px;
}
.mobile-cart-pop-up .cart-product-card-content {
  margin-left: 0;
  margin-right: 0;
  font-size: 12px;
}
.mobile-cart-title-container {
  display: flex;
  justify-content: space-between;
  /* border-bottom: 1px solid grey; */
  /* padding: 1vw; */
  box-sizing: border-box;
  font-size: 12px;
  height: 6%;
  align-items: center;
  width: 95%;
  margin: auto;
}
.mobile-cart-title-container > button {
  margin-right: 1%;
  font-size: 12px;
}
/* MISC */

.mobile-misc-page {
  min-height: 92vh;
  width: 90%;
  margin-top: 6%;
  margin-right: auto;
  margin-left: auto;
  font-size: 14px;
  margin-bottom: 10%;
  font-family: "swall-diatype";
}
.site-credit-mobile {
  font-size: 14px;
}
