/* fonts */

@font-face {
  font-family: "tobias-regular";
  src: url("./fonts/Tobias-Regular.otf") format("opentype");
}

@font-face {
  font-family: "swall-diatype";
  src: url("./fonts/SwallStudiosDiatype-Regular.otf") format("opentype");
}
a {
  color: var(--blue);
  text-decoration: none;
}
button {
  cursor: pointer;
  background: none;
  color: black;
  border: none;
  text-decoration: underline;
  padding: 0%;
  width: fit-content;
  font-family: "swall-diatype";
}

img {
  width: 100%;
}

/* insert repetitve variables like font sizes and colors 
so if we need to fix them we can fix across the board
instead of tweaking each class */
:root {
  --medium-text-size: 1.3vw;
  --base-text-size: 0.75rem;
  --blue: #431cec;
}
.blue {
  color: var(--blue);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 10;
  background-color: white;
  width: 100vw;
}
.navbar-container {
  width: 100%;
  border-top: 1px solid #bfbfbf;
  border-bottom: 1px solid #bfbfbf;
  background-color: white;
}
.navbar {
  height: 54px;
  width: 95%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-family: "swall-diatype";
  position: relative;
}

.navbar-left,
.navbar-right {
  display: flex;
  align-items: center;
}

.navbar-left {
  justify-content: flex-start;
  width: 40%;
  gap: 4%;
}

.navbar-right {
  justify-content: flex-end;
  width: 40%;
  gap: 4%;
}

/* .navbar-left:nth-child(2),
.navbar-right:nth-child(2) {
  margin: auto 2%;
} */

.navbar-center {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
}

.navbar-image {
  width: clamp(100px, 10%, 100px);
  cursor: pointer;
}

.navbar-link {
  margin: 0;
  background-color: transparent;
  border: none;
  color: black;
  cursor: pointer;
  text-decoration: none;
  font-size: 12px;
  display: block;
}
.dimepiece-logo {
  color: var(--blue);
}
.search {
  position: fixed;
  top: 54px;
  width: 100%;
  background-color: #d7ceff;
  color: var(--blue);
  box-sizing: border-box;
  z-index: -1;
  overflow: hidden;
}
.search-container {
  width: 95%;
  margin: auto;
  background-color: #d7ceff;
  color: var(--blue);
  padding-block-start: 1rem;
  font-family: "swall-diatype";
}
.search-input-container {
  font-size: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 2;
}
.search-input-container > input {
  background-color: #d7ceff;
  color: var(--blue);
  caret-color: var(--blue);
  border: none;
  outline: none;
  font-size: 3rem;
  padding: 0;
  padding-bottom: 1rem;
  width: 95%;
  font-family: "swall-diatype";
}
.search-input-container > button {
  margin: 0;
  margin-bottom: 0.75%;
  font-size: 3rem;
  color: var(--blue);
  text-decoration: none;
}
.suggestions-container {
  width: 90%;
  line-height: 140%;
  transform-origin: top;
  box-sizing: border-box;
  padding-bottom: 1rem;
}
.suggestion-title {
  /* text-decoration: underline; */
  font-family: "swall-diatype-bold" !important;
  margin-block: 0.75rem;
}
.suggestion-arrow-container {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  cursor: pointer;
  align-items: center;
}
.suggestion {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.suggestion-arrow-container > p {
  margin: 0;
}
/* Featured Story / Top of Homepage Section */

.featured-story {
  display: flex;
  flex-direction: row-reverse;
  min-height: calc(100vh - 54px);
  margin-bottom: 7%;
  /* min-height: 100vh; */
  height: calc(100vh - 54px);
}
.featured-story-img {
  width: 50%;
  object-fit: cover;
  cursor: pointer;
}

.featured-story-blurb-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.featured-story-blurb-container-inside {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 1em;
}

.featured-story-preview-title {
  font-size: clamp(2.2em, 6.93vw, 5.2em);
  line-height: 100%;
  font-family: "tobias-regular";
  font-weight: normal;
  margin: 0;
}

.featured-story-preview-description {
  margin: 0;
  font-size: var(--medium-text-size);
  line-height: 125%;
  font-family: "swall-diatype";
}

.read-story-btn {
  font-size: 1.2em;
}

.section-title-home {
  margin: auto;
  text-align: center;
  font-size: 1.4rem !important;
  margin-top: -0.25% !important;
  font-family: "swall-diatype-bold" !important;
}

/* Latest Stories Homepage Section */

.latest-stories {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 95%;
  margin: auto;
  row-gap: 3em;
  margin: 2% auto 7%;
}
.latest-story-card {
  width: 24%;
  cursor: pointer;
}
.latest-story-card-date {
  font-family: "swall-diatype";
}
.latest-story-card-top {
  width: 100%;
  margin-bottom: 2%;
}
.latest-story-card-top > img {
  object-fit: cover;
  aspect-ratio: 2/3;
}
.latest-story-card-bottom {
  display: flex;
  gap: 1.35rem;
}
.latest-story-card-bottom-left {
  display: flex;
  flex-direction: column;
  font-family: "swall-diatype";
  font-size: clamp(4px, 0.58vw, 1rem);
  width: 11.5%;
  letter-spacing: -0.2px;
}
.latest-story-card-bottom-left > p {
  margin-top: 0.3rem;
}
.latest-story-card-bottom-left .blue {
  margin-bottom: 2%;
}
.latest-story-card-bottom-left > p:last-of-type {
  margin-top: 0rem;
}
.latest-story-card-bottom-right {
  font-family: "tobias-regular";
  display: flex;
  flex-direction: column;
  width: 100%;
}
.latest-story-card-title {
  margin: 0;
  font-size: clamp(32px, 1.8vw, 1.8rem);
  line-height: 110%;
}
.most-discussed {
  width: 40%;
}
.most-discussed .section-title-home {
  margin-bottom: 4% !important;
}
.most-discussed-card {
  width: 100%;
  cursor: pointer;
}
.most-discussed-preview {
  font-size: 1.2rem;
  font-family: "swall-diatype";
  line-height: 110%;
}
.most-discussed-preview-button {
  font-size: 0.9rem;
}
/* Latest Watches Section */

.latest-watches {
  text-align: center;
  margin-bottom: 7%;
}

.watch-preview-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 75%;
  margin: auto;
  row-gap: 1rem;
}
.watch-preview-card {
  width: 20%;
  text-align: center;
  font-family: "swall-diatype";
  margin-top: 3%;
  cursor: pointer;
}
.search-results .watch-preview-card {
  margin-top: -2%;
}

.watch-preview-card-details > p {
  font-size: var(--base-text-size);
  line-height: 125%;
  margin: 0%;
  letter-spacing: -0.15px;
}

/* brynns pick */

.featured-watch {
  margin-bottom: 7%;
}

.featured-watch-headline {
  text-align: center;
  font-size: clamp(24px, 3.33vw, 2.5em);
  font-family: "tobias-regular";
  width: 40%;
  margin: 2% auto 2%;
}

.featured-watch-content-container {
  display: flex;
  flex-direction: row; /* Horizontal layout */
  width: 65%;
  margin: auto;
}

.featured-watch-description {
  width: 30%;
  font-family: "swall-diatype";
  font-size: var(--base-text-size);
}

.featured-watch-description > p {
  margin-top: 0%;
}

.featured-watch-image {
  width: 50%;
  margin: 0 5%;
  cursor: pointer;
}

.featured-watch-details {
  width: 30%;
  font-family: "swall-diatype";
  display: flex;
  flex-direction: column;
  gap: 1em;
  font-size: var(--base-text-size);
}

.featured-watch-detail-container > h5,
.featured-watch-detail-container > p {
  margin: 0%;
  width: 50%;
  line-height: 120%;
}
/* LATEST VIDEO */

.latest-video {
  margin-bottom: 7% !important;
  width: 75%;
  margin: auto;
}
.video-container {
  margin-top: 3%;
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}
.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* bottom homepage section */

.three-column-homepage-container {
  width: 95%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 7%;
}
.three-column-homepage-container .section-title-home {
  margin-bottom: 7%;
}
/* dial dimepieces */
.dial-dimepiece {
  width: 25%;
}
.dial-dimepiece-cards-container {
  width: 100%;
}
.dial-dimepiece-card {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-bottom: 7%;
  cursor: pointer;
}
.dial-dimepiece-card-left {
  width: 50%;
}
.dial-dimepiece-card-left > img {
  aspect-ratio: 2/3;
  object-fit: cover;
}
.dial-dimepiece-card-right {
  overflow: hidden;
  width: 50%;
  font-size: var(--base-text-size);
  font-family: "tobias-regular";
}
.dial-dimepiece-title {
  font-size: 1.3rem;
  font-family: "tobias-regular";
  margin: 0;
  margin-top: -2%;
}
.dial-dimepiece-discussed {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 7;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.75rem;
  font-family: "swall-diatype";
}
.dial-dimepiece-card-right .most-discussed-preview-button {
  font-size: 0.75rem;
}
.dial-dimepiece-card-date {
  font-family: "swall-diatype" !important;
  margin: 0;
}
.dial-dimepiece-card-date .blue {
  font-family: "swall-diatype";
  margin: 0;
}
.dial-dimepiece-card-headers > p {
  margin: 0;
  border-bottom: 1px solid black;
  padding: 0.7rem 0;
}
.dial-dimepiece-card-headers > p:last-of-type {
  border: none;
}

/* brynns basics */
.brynns-basics {
  width: 25%;
}
.brynns-basics-cards-container,
.brynns-basics-card {
  width: 100%;
}
.brynns-basics-card {
  display: flex;
  flex-direction: row;
  margin-bottom: 8%;
}
.brynns-basics-card-right,
.brynns-basics-card-left {
  width: 50%;
}
.brynns-basics-card-left > img {
  cursor: pointer;
}
.brynns-basics-card-description {
  font-size: var(--base-text-size);
  font-family: "swall-diatype";
}
.brynns-basics-card-description > p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 7;
  overflow: hidden;
  text-overflow: ellipsis;
}
.brynns-basics-card-right-details > p {
  font-size: var(--base-text-size);
  margin: 1%;
}

.brynns-basics-card-button {
  font-size: var(--base-text-size);
}

/* newsletter */
.newsletter {
  text-align: center;
  margin: 0 auto 7%;
  width: 30%;
  font-size: 1.4rem;
  font-family: "swall-diatype";
}
.newsletter > p {
  margin: 5% auto;
}
.newsletter-input-container {
  width: 100%;
  border-bottom: 1px solid black;
  margin: 12% auto;
  display: flex;
  justify-content: space-between;
}
.newsletter-input-container > input {
  width: 80%;
  border: none;
  font-size: 1.4rem;
  outline: none;
  font-family: "swall-diatype";
  margin-bottom: 0.5%;
}
.newsletter-input-container > input::placeholder {
  color: black;
}
.newsletter-input-container > button {
  font-size: 1.4rem;
  font-family: "swall-diatype";
  margin-bottom: 0.5%;
  margin-right: 0.5%;
  white-space: nowrap;
}
.success {
  animation: success 0.5s linear;
}
.failure {
  animation: failure 0.5s linear;
}
/* INDEX */
.index-container {
  width: 9%;
}
.content-index-container {
  width: 82%;
}
/* latest stories page */
.stories-page {
  display: flex;
  padding-top: 3%;
  padding-bottom: 3%;
  padding-left: 2.5%;
  padding-right: 2.5%;
  min-height: 100vh;
  margin-bottom: 5%;
  position: relative;
}
.stories-page .latest-story-card {
  width: 32.46%;
}
.stories-page-index {
  width: 100%;
  height: fit-content;
  position: sticky;
  position: -webkit-sticky;
  top: calc(58px + (95vw * 0.03));
  font-family: "swall-diatype";
}

.stories-page-index-list {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-bottom: 20%;
  font-size: var(--base-text-size);
}
.stories-page-index-list > ol {
  padding: 0;
  list-style-position: inside;
  margin-bottom: 0%;
  font-family: "tobias-regular";
  margin-top: 0;
}
.index-link {
  margin: 0;
  background-color: transparent;
  border: none;
  color: black;
  cursor: pointer;
  text-decoration: none;
  line-height: 130%;
  font-family: "swall-diatype";
}
.index-link:first-of-type {
  margin-top: 3%;
}
.stories-page-index-category-header {
  margin: auto 0% 3%;
  cursor: pointer;
}
.stories-page-index-list > ol {
  list-style-position: outside;
  padding-left: 20px;
}
.stories-page-index-list > ol > li {
  margin-bottom: 10%;
  margin-top: 5%;
  line-height: 125%;
  cursor: pointer;
}
.stories-page-index-brynns-pick {
  line-height: 125%;
  cursor: pointer;
  margin-top: 3%;
}
.stories-page-content {
  width: 100%;
}
.stories-page-content > p {
  width: 60%;
  text-align: center;
  font-family: "swall-diatype";
  line-height: 130%;
  margin: 1% auto 2%;
  font-size: 1rem;
}
.stories-page-card-container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 1.3%;
  row-gap: 3em;
  margin: auto;
  width: 90%;
  margin-bottom: 3%;
}
.stories-page .latest-story-card-title {
  font-size: 1.5em;
}

/* article */
.article {
  text-align: center;
  padding: 2.5%;
  min-height: 100vh;
}
.scroll-progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 55px;
  width: 100%;
  /* z-index: -1; */
  background: #d7ceff;
  transform-origin: 0%;
}
.author-photog-container {
  margin-bottom: 1%;
  margin-top: 1%;
  font-family: "swall-diatype";
}
.author-photog-container > p:first-of-type {
  margin-bottom: 0;
}
.author-photog-container > p:last-of-type {
  margin-top: 0;
}
.article > p:first-of-type {
  font-family: "tobias-regular";
  font-size: 6rem;
  width: 75%;
  margin: auto;
}
.article-cover-img {
  width: 50%;
  margin: auto;
  margin-top: 1%;
  margin-bottom: 1.5%;
}
.article-sanity-content > p {
  width: 50%;
  margin: auto;
  text-align: left;
  font-family: "swall-diatype";
}
.article-sanity-content > h2 {
  font-family: "swall-diatype";
  font-size: 1.5rem;
}
.article-images-container {
  width: 100%;
  display: flex;
  gap: 2vw;
  overflow: auto;
  margin-top: 1.5%;
  margin-bottom: 1.25%;
}
.article-images-container::-webkit-scrollbar {
  display: none;
}
.sanity-article-image-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 0 auto;
  font-family: "swall-diatype";
  color: grey;
  font-size: 1rem;
}
.sanity-mobile-article-image-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: auto;
  font-family: "swall-diatype";
  color: grey;
  font-size: 16px;
}
.sanity-article-image-container > p,
.sanity-mobile-article-image-container > p {
  margin-top: 1%;
  margin-bottom: 0;
}
.article-button-container {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: fit-content;
  align-items: center;
}
/* WATCHES PAGE */
.watch-modifier {
  width: 100%;
}
.watches-page-card-container {
  display: flex;
  flex-wrap: wrap;
  row-gap: 1em;
  margin: auto;
  width: 100%;
}
/* watch prooduct page */
.product-container {
  display: flex;
  justify-content: space-between;
  width: 112%;
  height: 100vh;
}
.product-images-container {
  width: 60%;
  overflow: auto;
  scrollbar-color: transparent transparent;
  scrollbar-width: thin;
  margin-left: 0.5%;
}
.product-images-container::-webkit-scrollbar {
  width: 0px;
}
.product-images-container::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.product-image {
  margin-bottom: 4%;
}
.product-image:last-of-type {
  margin: 0;
}
.product-description-container {
  width: 35%;
  display: flex;
  flex-direction: column;
  gap: 4%;
  padding-left: 1%;
  padding-right: 1%;
  overflow: auto;
  scrollbar-color: transparent transparent;
  scrollbar-width: thin;
}
.product-description-container::-webkit-scrollbar {
  width: 0px;
}
.product-description-container::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.watch-description-table {
  outline: 1px solid black;
}
.watch-description-table-row {
  /* border-top: 1px solid black; */
  border-bottom: 1px solid black;
  padding: 2%;
  display: flex;
  justify-content: space-between;
}
.watch-description-table-row:last-of-type {
  border-bottom: none;
}
.product-description-container .watch-preview-card-details > p {
  font-size: var(--base-text-size);
  font-family: "swall-diatype";
}
.watch-description-table-row > p {
  font-family: "swall-diatype";
  margin: 0%;
}
.watch-description-table-row-key {
  font-size: 0.5rem;
  /* padding-left: 1%; */
}
.watch-description-table-row-value {
  margin: 0;
  width: 40%;
  font-size: var(--base-text-size);
  text-align: right;
  /* padding-right: 1%; */
  line-height: 120%;
}
.watch-description-buttons-container > button {
  width: 100%;
  padding: 2%;
  border: 1px solid black;
  /* background: transparent; */
  text-decoration: none;
  font-size: var(--base-text-size);
}
.watch-description-buttons-container > button:last-of-type {
  background: black;
  color: white;
  margin-top: 1.5%;
}
.watch-description-summary {
  font-size: var(--base-text-size);
  font-family: "swall-diatype";
}
/* Newsletter page */
.newsletter-page {
  min-height: calc(100vh - 54px);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.newsletter-page .newsletter {
  width: 40%;
  margin: auto;
}
/* misc */
.misc-page {
  min-height: 92vh;
  width: 50%;
  margin-top: 3%;
  margin-right: auto;
  margin-left: auto;
  font-size: 1rem;
  margin-bottom: 5%;
  font-family: "swall-diatype";
}

/* ABOUT PAGE */
.about-page {
  width: 100%;
  min-height: 100vh;
}
.about-page-container {
  width: 50%;
  margin: auto;
}

.about-header-title,
.about-header-title-full {
  text-align: center;
  font-size: clamp(24px, 3.33vw, 2.5em);
  font-family: "tobias-regular";
  margin-bottom: 15%;
}

.about-header-title-full
  .article-images-container
  .sanity-article-image-container {
  width: 100% !important;
}
/* .about-header-title .article-images-container > img {
  width: 49% !important;
} */

.about-brynn-portrait {
  font-size: 1rem;
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 15%;
}

.about-brynn-portrait-title {
  text-align: center;
  font-size: clamp(24px, 3.33vw, 2.5em);
  font-family: "tobias-regular";
}

.about-brynn-portrait > p {
  margin-top: 0%;
  font-family: "swall-diatype";
}

.about-brynn-portrait img {
  width: 50%;
  margin-right: 2.5%;
}

.about-brynn-portrait .portable-text-container {
  width: 50%;
  margin: 0;
  padding: 0;
}
/* .carousel-container {
  position: relative; */
/* display: flex; */
/* justify-content: space-between; */
/* white-space: nowrap;
  overflow: hidden;
  width: 100%;
  min-height: 100px;
  margin-bottom: 8%;
  margin-top: 4%;
} */
.gradient {
  background: -moz-linear-gradient(
    right,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  ); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    top left,
    top right,
    right,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    right,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    right,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  ); /* Opera 11.10+ */
  background: -ms-linear-gradient(
    right,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  ); /* IE10+ */
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  );
  background-attachment: fixed;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
}
.slider {
  height: 250px;
  margin: auto;
  position: relative;
  width: 90%;
  display: grid;
  place-items: center;
  overflow: hidden;
}
.slide-track {
  display: flex;
  width: calc(250px * 14);
  animation: scroll 25s linear infinite;
}
.slide {
  height: 200px;
  width: 250px;
  display: flex;
  align-items: center;
  margin-left: 2%;
}
.slide > img {
  width: 100%;
}
/* .slider::before,
.slider::after {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  content: "";
  height: 100%;
  position: absolute;
  width: 15%;
  z-index: 2;
}
.slider::before {
  left: 0;
  top: 0;
}
.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(100deg);
} */
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}
/* .slider {
  width: fit-content;
  display: flex;
  gap: 5rem;
  flex-wrap: nowrap;
  animation: scroll 20s linear infinite;
} */
/* .slider > img {
  max-width: 10%;
  object-fit: contain;
} */
.about-dimepiece-press {
  display: flex;
  align-items: flex-start;
  margin-bottom: 2%;
  width: 49%;
  font-size: 1rem;
  font-family: "swall-diatype";
  height: 290px;
  overflow: hidden;
}
.about-section-container {
  width: 95%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 2%;
  margin-bottom: -5%;
}
.about-dimepiece-press:last-of-type {
  margin-bottom: 15%;
}

.about-dimepiece-press-title {
  font-size: 1.5rem;
  font-family: "tobias-regular";
  margin: 0;
  cursor: pointer;
}

.about-dimepiece-press img {
  width: 50%;
  height: 100%;
  margin-right: 2%;
  object-fit: cover;
  aspect-ratio: 2/1;
  cursor: pointer;
}

.about-dimepiece-press div {
  width: 50%;
  height: 100%;
  --base-text-size: 0.75rem;
}

.dimepiece-press-text {
  margin-top: 3%;
  font-size: 14px;
  font-family: "swall-diatype";
}

.about-dimepiece-press .blue {
  margin-top: 0%;
  font-family: "tobias-regular" !important;
  margin-bottom: 3%;
  font-size: 1.5rem;
}

.about-say-hello {
  text-align: center;
  line-height: 20%;
  font-size: 1.5rem;
  font-family: "swall-diatype";
  margin-bottom: 5%;
}

/* Cart */
.cart-overlay {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(77, 77, 77, 0.777);
  z-index: 11;
}
.cart-pop-up {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 35vw;
  border: 1px solid black;
  background: white;
  z-index: 12;
  transform-origin: 100%;
  font-family: "swall-diatype";
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 0.75rem;
}
.cart-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid grey;
  /* padding: 1vw; */
  box-sizing: border-box;
  min-height: 55px;
}
.close-cart-btn {
  text-decoration: none;
  margin-right: 1vw;
}
.cart-pop-up h2 {
  margin-left: 4%;
  font-weight: normal;
  font-size: 0.75rem;
  font-family: "swall-diatype";
}
.cart-product-cards-hold {
  height: 77vh;
  overflow: auto;
}
.cart-product-cards-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* padding-top: 5%;
  padding-bottom: 3%; */
  height: 100%;
}
.cart-product-card-content {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  /* height: 77vh; */
  overflow: auto;
  border: solid 1px;
  margin-left: 1vw;
  margin-right: 1vw;
  margin-top: 2%;
  font-size: var(--base-text-size);
}
.cart-product-cards-container > div:first-of-type {
  margin-top: 4%;
}
.cart-product-cards-container > div:last-of-type {
  padding-bottom: 6%;
}
.cart-product-card-img-container {
  width: 30%;
  display: flex;
  align-items: center;
}
.cart-product-card-img-container > img {
  width: 100%;
  border-right: solid 1px;
}
.cart-product-title-container {
  width: 55%;
  margin-top: 2%;
  margin-left: 3%;
}
.cart-product-brand-title > p {
  margin: 0;
  font-family: "swall-diatype";
}
.cart-price-container {
  width: 15%;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  margin-bottom: 2%;
  margin-top: 2%;
}
.cart-price-container > p {
  margin: 0;
}
.cart-footer {
  border-top: 1px solid grey;
  font-weight: bold;
  font-family: "swall-diatype";
}
.cart-subtotal-container {
  display: flex;
  justify-content: space-between;
  padding: 1vw;
  padding-bottom: 0.5vw;
  box-sizing: border-box;
}
.cart-pop-up .watch-description-buttons-container {
  padding: 1vw;
  padding-top: 0.5vw;
  box-sizing: border-box;
}
.cart-pop-up .watch-description-buttons-container > button {
  margin: 0;
}
/* FOOTER */

.footer {
  background-color: #d7ceff;
  font-size: var(--base-text-size);
  padding: 2% 2.5%;
  display: flex;
  justify-content: space-between;
}
.footer-left-container {
  display: flex;
  width: 50%;
  font-family: "swall-diatype";
}
.footer-right-container > button {
  display: flex;
  gap: 0.5rem;
}
.footer-right-container > button > p {
  white-space: nowrap;
  margin: 0;
}
.footer-left-container > div > p,
.footer-left-container > div > div > p {
  margin: 0;
}
.footer-links {
  width: fit-content;
  margin: 0 6%;
}
.footer-left-column {
  width: fit-content;
  display: flex;
  flex-direction: column;
  margin: 0;
}
.footer > div > button {
  text-decoration: none;
}
/* 404 */

.page-not-found {
  width: 50%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3%;
}

.fourohfour-text {
  margin: auto;
  font-family: "swall-diatype";
  text-align: center;
  font-size: 1.4rem;
  padding-top: 5%;
  margin-bottom: 2%;
}
.fourohfour-button {
  background-color: transparent;
  color: black;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s ease;
  font-family: "swall-diatype";
  text-decoration: underline;
}
/* MEDIA QUERIES */

@media (max-width: 600px) {
  :root {
    --base-text-size: 10px;
  }
}

@media (min-width: 601px) and (max-width: 768px) {
  /* styles */
  .featured-watch-content-container {
    width: 95%;
  }
  .featured-watch-detail-container > p {
    width: 100%;
  }
  .featured-watch-description,
  .featured-watch-details,
  .featured-watch-description button {
    font-size: 0.5rem;
  }
  .newsletter {
    width: 50%;
  }
  .about-dimepiece-press .blue {
    font-size: 0.75rem;
  }
  .about-dimepiece-press-title {
    font-size: 1rem;
  }
  .dimepiece-press-text {
    font-size: 0.67rem;
  }
  .about-brynn-portrait {
    flex-direction: column;
  }
  .about-brynn-portrait > img {
    width: 75%;
  }
  .index-container {
    width: 15%;
  }
  .content-index-container {
    width: 70%;
  }
  .stories-page-content > p {
    width: 65%;
  }
  .featured-story-preview-title {
    font-size: 3.9vw;
  }
  .stories-page .latest-story-card {
    width: 49%;
    margin-left: auto;
    margin-right: auto;
  }
  .stories-page .latest-story-card-title {
    font-size: 0.75em;
  }
  .dial-dimepiece-title {
    font-size: 0.7rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
  }
  .dial-dimepiece-discussed,
  .dial-dimepiece-card-right .most-discussed-preview-button,
  .brynns-basics-card-right-details > p,
  .brynns-basics-card-description,
  .brynns-basics-card-button {
    font-size: 0.5rem;
  }
  .dial-dimepiece-discussed {
    -webkit-line-clamp: 3;
  }
  .most-discussed-preview,
  .most-discussed-preview-button {
    font-size: 0.6rem;
  }
  .latest-story-card-bottom {
    display: flex;
    gap: 0.5rem;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  /* styles */
  .featured-watch-content-container {
    width: 95%;
  }
  .featured-watch-detail-container > p {
    width: 100%;
  }
  .featured-watch-description,
  .featured-watch-details,
  .featured-watch-description button {
    font-size: 1vw;
  }
  .newsletter {
    width: 45%;
  }
  .about-dimepiece-press .blue {
    font-size: 1.5vw;
  }
  .about-dimepiece-press-title {
    font-size: 1.75vw;
  }
  .dimepiece-press-text {
    font-size: 1.3vw;
  }
  .about-brynn-portrait {
    flex-direction: column;
  }
  .about-brynn-portrait > img {
    width: 75%;
  }
  .index-container {
    width: 15%;
  }
  .content-index-container {
    width: 70%;
  }
  .stories-page-content > p {
    width: 65%;
  }
  .featured-story-preview-title {
    font-size: 4.2vw;
  }
  .stories-page .latest-story-card {
    width: 49%;
    margin-left: auto;
    margin-right: auto;
  }
  .stories-page .latest-story-card-title {
    font-size: 1em;
  }
  .dial-dimepiece-title {
    font-size: 0.8rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .dial-dimepiece-discussed,
  .dial-dimepiece-card-right .most-discussed-preview-button,
  .brynns-basics-card-right-details > p,
  .brynns-basics-card-description,
  .brynns-basics-card-button {
    font-size: 0.6rem;
  }
  .dial-dimepiece-discussed {
    -webkit-line-clamp: 3;
  }
  .most-discussed-preview,
  .most-discussed-preview-button {
    font-size: 0.75rem;
  }
  .latest-story-card-bottom {
    display: flex;
    gap: 0.8rem;
  }
}

@media (min-width: 1025px) and (max-width: 1440px) {
  /* styles */
  .featured-watch-description,
  .featured-watch-details,
  .featured-watch-description button {
    font-size: 0.9vw;
  }
  .newsletter {
    width: 40%;
  }
  .about-dimepiece-press .blue {
    font-size: 1.5vw;
  }
  .about-dimepiece-press-title {
    font-size: 1.75vw;
  }
  .dimepiece-press-text {
    font-size: 1vw;
  }
  .index-container {
    width: 12%;
  }
  .content-index-container {
    width: 75%;
  }
  .featured-story-preview-title {
    font-size: 4.7vw;
  }
  .stories-page .latest-story-card {
    width: 49%;
    margin-left: auto;
    margin-right: auto;
  }
  .stories-page .latest-story-card-title {
    font-size: 1.25em;
  }
  .dial-dimepiece-title {
    font-size: 1rem;
  }
  .dial-dimepiece-discussed,
  .dial-dimepiece-card-right .most-discussed-preview-button,
  .brynns-basics-card-right-details > p,
  .brynns-basics-card-description,
  .brynns-basics-card-button {
    font-size: 0.6rem;
  }
  .most-discussed-preview,
  .most-discussed-preview-button {
    font-size: 0.75rem;
  }
  .latest-story-card-bottom {
    display: flex;
    gap: 1.2rem;
  }
}

@media (min-width: 1425px) {
  /* styles */
  .featured-story-preview-title {
    font-size: 5.7vw;
  }
}
@media (max-width: 1559px) {
  /* styles */
  .featured-watch-headline {
    font-size: 2.5vw;
  }
  /* .dial-dimepiece-title {
    font-size: 1.15rem;
  } */
}
/* 
@keyframes scroll {
  to {
    transform: translate(calc(-100% - 5rem));
  }
} */

@keyframes success {
  0% {
    box-shadow: none;
  }
  33% {
    box-shadow: 0 0 2px 2px green;
  }
  50% {
    box-shadow: 0 0 3px 3px green;
  }
  66% {
    box-shadow: 0 0 2px 2px green;
  }
  100% {
    box-shadow: none;
  }
}
@keyframes failure {
  0% {
    box-shadow: none;
  }
  33% {
    box-shadow: 0 0 2px 2px red;
  }
  50% {
    box-shadow: 0 0 3px 3px red;
  }
  66% {
    box-shadow: 0 0 2px 2px red;
  }
  100% {
    box-shadow: none;
  }
}

.site-credit {
  cursor: url("./assets/watch-cursor.png"), pointer;
  width: fit-content;
}
